import appStrings from '@/app/utility/string.utility.js';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
    name: 'ReceiptUpload',
    components: {
    },
    watch: {
        currentPage: function () {
            this.getRecieptUploadData();
        },
        perPage: function () {
            this.currentPage = 1;
            this.getRecieptUploadData();
        },
        selectAllCheckBox: function () {
            this.checkUncheckAll();
        }
    },
    data() {
        return {
            totalRows: null,
            perPage: commonHelper.perPageRecord,
            pageOptions: commonHelper.getPageOption(),
            currentPage: 1,
            loader: false,
            editMode: false,
            module: null,
            legalEntity: null,
            operatingUnit: null,
            insNum: null,
            fileName: null,
            status: {
                value: null,
                text: null
            },
            showValueSetModal: false,
            unsubscribe: null,
            recieptUploadData: [],
            recieptUploadFields: [
                {
                    key: 'select',
                    stickyColumn: true,
                    variant: 'primary'
                },
                {
                    key: 'status_flag',
                    label: 'Status'
                },
                {
                    key: 'status_msg',
                    label: 'Status Message'
                },
                {
                    key: 'loader_id'
                },
                {
                    key: 'file_name'
                },
                {
                    key: 'module'
                },
                {
                    key: 'le_name',
                    label: 'Legal Entity'
                },
                {
                    key: 'ou_name',
                    label: 'Operating Unit'
                },
                {
                    key: 'application_type'
                },
                {
                    key: 'comments'
                },
                {
                    key: 'customer_name'
                },
                {
                    key: 'customer_site_name'
                },
                {
                    key: 'exchange_rate'
                },
                {
                    key: 'gl_date',
                    label: 'GL Date'
                },
                {
                    key: 'instrument_amount'
                },
                {
                    key: 'instrument_date'
                },
                {
                    key: 'instrument_num'
                },
                {
                    key: 'party_receipt_method'
                },
                {
                    key: 'project_code'
                },
                {
                    key: 'receipt_mode'
                },
                {
                    key: 'receipt_type'
                },
                {
                    key: 'tds_amount'
                },
                {
                    key: 'tds_receipt_method'
                },
                {
                    key: 'value_date'
                },
                {
                    key: 'bank_branch'
                },
                {
                    key: 'bank_name'
                },
                {
                    key: 'currency'
                },
                {
                    key: 'trx_num',
                    label: 'Trx No.'
                },
                {
                    key: 'tds_trx_num',
                    label: 'TDS Trx No.'
                },
                {
                    key: 'amount_applied'
                },
                {
                    key: 'tds_amount_applied',
                    label: 'TDS Amount Applied'
                }, 
                {
                    key:'meter_no',
                    label:'Meter Number'
                }
            ],
            showExcelUploadModal: false,
            requestId: null,
            templateId: null,
            requestNumId: null,
            requestStatus: null,
            selectAllCheckBox: false,
            payload: {}
        };
    },
    mounted() {
        this.unsubscribe = this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'shared/setActionName') {
                const actionName = state.shared.actionName;
                if (actionName === 'upload') {
                    this.showExcelUploadModal = true;
                }
                if (actionName === 'delete') {
                    this.deleteRecieptUpload();
                }
                if (actionName === 'download') {
                    this.loader = true;
                    /**
                     * @param(payload, 'action name', 'file name')
                     */
                    const downloadpayload = { ...this.payload };
                    downloadpayload._limit = this.totalRows;
                    this.downloadExcel.downloadData(
                        downloadpayload,
                        'receivables/getRecieptUploadData',
                        'receipt-upload',
                        () => (this.loader = false)
                    );
                }
            }
        });
    },
    methods: {
        checkUncheckAll() {
            this.recieptUploadData = this.recieptUploadData.map((data) => {
                data.select = data.status_flag !== 'PROCESSED' && this.selectAllCheckBox;
                return data;
            });
        },
        deleteRecieptUpload() {
            const promt = confirm('Are you sure, you want to delete this Receipt Upload?');
            if (promt) {
                const filterData = this.recieptUploadData.filter(data => data.select);
                const recieptUpload = filterData.map(elem => {
                    return {
                        id: elem.loader_id
                    };
                });
                const payload = { loader_id: recieptUpload ? recieptUpload : null }
                this.loader = true;
                this.$store
                    .dispatch('receivables/deleteRecieptUpload', payload)
                    .then(response => {
                        this.loader = false;
                        if (response.status === 200) {
                            this.editMode = false;
                            this.showAlert = true;
                            this.isSuccess = true;
                            alert('Record Deleted Successfully');
                        } else {
                            this.showAlert = true;
                            this.isSuccess = false;
                            alert(response.response.data.message)
                        }
                    })
            }
        },
        getRecieptUploadData() {
            this.payload = {
                _page: this.currentPage - 1,
                _limit: this.perPage,
                module: this.module,
                le_name: this.legalEntity,
                ou_name: this.operatingUnit,
                status_flag: this.status.value,
                instrument_num: this.insNum,
                file_name: this.fileName
            };
            this.loader = true;
            this.$store
                .dispatch('receivables/getRecieptUploadData', this.payload)
                .then(response => {
                    this.loader = false;
                    if (response.status === 200) {
                        this.recieptUploadData = response.data.data.page;
                        this.totalRows = response.data.data.total_elements;
                    }
                })
                .catch(() => {
                    this.loader = false;
                });
        },
        getTemplateDetails(item) {
            const payload = {
                _page: 0,
                _limit: 10,
                template_name: 'REC_RECEIPTS_MIG_TEMPL'
            };
            this.loader = true;
            this.$store
                .dispatch('assets/getTemplateDetails', payload)
                .then(resp => {
                    this.loader = false;
                    if (resp.status === 200) {
                        this.requestId = resp.data.data.page[0].request_id;
                        this.templateId = resp.data.data.page[0].template_id;
                        this.saveSubmitRequest(item);
                    }
                })
                .catch(() => {
                    this.loader = false;
                });
        },
        saveSubmitRequest(item) {
            const payload = {
                admin_submit_req_details: [
                    {
                        request_num: 0,
                        request_id: this.requestId,
                        template_id: this.templateId,
                        schedule_type: 'asap', //pass hardcode
                        request_start_date: null,
                        request_end_date: null,
                        resubmit_interval: null,
                        resubmit_interval_unit: null,
                        day_of_month: null,
                        day_of_week: null,
                        output_format_id: 'SBLANK', //pass hardcode
                        no_of_args: 25,
                        request_parameter: null,
                        sms_flag: false,
                        email_flag: false,
                        whatsapp_flag: false,
                        child_req_count: null,
                        email_comm_template_id: null,
                        sms_comm_template_id: null,
                        whatsapp_comm_template_id: null,
                        argument1: null,
                        argument2: null,
                        argument3: null,
                        argument4: null,
                        argument5: null,
                        argument6: null,
                        argument7: null,
                        argument8: null,
                        argument9: null,
                        argument10: null,
                        argument11: null,
                        argument12: null,
                        argument13: null,
                        argument14: null,
                        argument15: null,
                        argument16: null,
                        argument17: null,
                        argument18: null,
                        argument19: null,
                        argument20: null,
                        argument21: null,
                        argument22: null,
                        argument23: null,
                        argument24: null,
                        argument25: null
                    }
                ]
            };
            this.loader = true;
            this.$store
                .dispatch('template/saveSubmitRequest', payload)
                .then(resp => {
                    this.loader = false;
                    if (resp.status === 201) {
                        this.requestNumId = resp.data.data[0].id;
                        this.getSubmitRequest(item);
                    }
                })
                .catch(() => {
                    this.loader = false;
                });
        },
        getSubmitRequest() {
            this.loader = true;
            this.$store
                .dispatch('template/getSubmitRequest', this.requestNumId)
                .then(resp => {
                    this.loader = false;
                    if (resp.status === 200) {
                        this.requestStatus = resp.data.data[0].status;
                        this.reqId = resp.data.data[0].id;
                    }
                })
                .catch(() => {
                    this.loader = false;
                });
        },
        openValueSetModal(vsetCode) {
            this.vsetCode = vsetCode;
            this.showValueSetModal = true;
            this.setTimeVsetCode = setTimeout(() => {
                const vSetData = {
                    valueSetName: vsetCode,
                    multiFlag: null
                };
                this.eventBus.$emit('valueSetCode', vSetData);
            }, 0);
        },
        selectedvalueSet(item) {
            if (this.vsetCode === appStrings.VALUESETTYPE.STATUS_FLAG) {
                this.status.text = item.value_meaning;
                this.status.value = item.value_code;
            }
        },
        closeValueSetModal() {
            this.showValueSetModal = false;
        },
        clearVsetValues(vsetCode) {
            if (vsetCode === appStrings.VALUESETTYPE.STATUS_FLAG) {
                this.status.text = null;
                this.status.value = null;
            }
        },
        clearFilters() {
            this.module = null;
            this.legalEntity = null;
            this.operatingUnit = null;
            this.insNum = null;
            this.fileName = null;
            this.status = { value: null, text: null }
            this.recieptUploadData = [];
            this.totalRows = null;
            this.currentPage = 1;
            this.requestStatus = null;
        }
    },
    beforeDestroy() {
        this.unsubscribe();
    }
};
